import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";
import { getInvoiceNumber } from "../../../Components/utils";
import moment from "moment";

function InvoiceDetailsPDF({ invoiceData }) {
  const invoiceDate =
    moment(
      invoiceData?.statusUpdateTimes?.find(
        (item) => item.status === "payment_completed"
      )?.time,
      "x"
    ).format("DD MMM YYYY hh:mm A") || moment().format("DD MMM YYYY hh:mm A");
  return (
    <View style={styles.billContainer}>
      <View
        style={{
          ...styles.flexOne,
          padding: 10,
          borderRightColor: "#000",
          borderRightWidth: "1px",
          borderRightStyle: "solid",
        }}
      >
        <Text style={styles.textBody}>{`Billed To : ${
          invoiceData?.user?.name || "N/A"
        }`}</Text>
      </View>
      <View style={{ ...styles.flexOne, padding: 10 }}>
        <Text style={styles.textBody}>{`Invoice Date : ${invoiceDate}`}</Text>
        <Text style={styles.textBody}>
          Invoice number : {getInvoiceNumber(invoiceData?._id)}
        </Text>
        <Text style={styles.textBody}>Place of Supply : Kerala</Text>
      </View>
    </View>
  );
}

export default InvoiceDetailsPDF;
