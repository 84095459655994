import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import moment from "moment";
import { getInvoiceNumber } from "../../../../Components/utils";

function BillDetails({ invoiceData }) {
  const invoiceDate =
    moment(
      invoiceData?.statusUpdateTimes?.find(
        (item) => item.status === "payment_completed"
      )?.time,
      "x"
    ).format("DD MMM YYYY hh:mm A") || moment().format("DD MMM YYYY hh:mm A");
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
      }}
    >
      <Box
        flex={1}
        sx={{ padding: { xs: 1, md: 2 }, borderRight: "1px solid #000" }}
      >
        <Typography sx={styles.textBody}>{`Billed To : ${
          invoiceData?.user?.name || "N/A"
        }`}</Typography>
      </Box>
      <Box flex={1} sx={{ padding: { xs: 1, md: 2 } }}>
        <Typography sx={styles.textBody} noWrap>
          {`Invoice Date : ${invoiceDate}`}
        </Typography>
        <Typography sx={styles.textBody}>
          Invoice number : {getInvoiceNumber(invoiceData?._id)}
        </Typography>
        <Typography sx={styles.textBody}>Place of Supply : Kerala</Typography>
      </Box>
    </Box>
  );
}

export default BillDetails;
