import { Font, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import NatoFont from "../../../Assets/fonts/NotoSans-Regular.ttf";

Font.register({
  family: "customFont",
  src: NatoFont,
});
const styles = {
  table: {
    display: "table",
    width: "auto",
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: (columnWidth) => ({
    width: columnWidth || "25%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    padding: 5,
  }),
  tableCell: (tAlign) => ({
    textAlign: tAlign || "right",
    fontSize: 10,
  }),
  tableHeadCell: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 10,
  },
};

function DetailTablePDF({ invoiceData }) {
  const [data, setData] = useState({ items: [], subAmount: 0, gst: 0 });
  useEffect(() => {
    const mData = [];
    if (invoiceData.dafyCommission) {
      mData.push({
        name: "Platform Fee",
        gstPer: invoiceData.fareType.companyGST / 2,
        gst: invoiceData.companyGst / 200,
        amount: invoiceData.dafyCommission / 100,
        hsn: invoiceData?.fareType.platformFeeHsn,
      });
    }
    if (invoiceData.driverCommission) {
      mData.push({
        name: "Driver Fee",
        gstPer: invoiceData.fareType.driverCommissionGST / 2,
        gst: invoiceData.driverCommissionGst / 200,
        amount: invoiceData.driverCommission / 100,
        hsn: invoiceData?.fareType.driverFeeHsn,
      });
    }
    if (invoiceData.insured) {
      mData.push({
        name: "Insurance Fee",
        gstPer: invoiceData.fareType.secureGST / 2,
        gst: invoiceData.secureAmountGst / 200,
        amount: invoiceData.secureAmount / 100,
        hsn: invoiceData?.fareType.secureFeeHsn,
      });
    }
    const gst = mData.reduce((total, item) => total + item.gst, 0);
    const subAmount = mData.reduce((total, item) => total + item.amount, 0);

    setData({ items: mData, subAmount, gst });
  }, [invoiceData]);
  return (
    <View style={styles.table}>
      {/* Table Header Row */}
      <View style={styles.tableRow}>
        {/* Description Column */}
        <View
          style={{
            ...styles.tableCol("25%"), // Adjusted width to accommodate HSN column
            borderRight: 0,
            borderBottom: 0,
            borderLeft: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>Description</Text>
        </View>

        {/* HSN Column */}
        <View
          style={{
            ...styles.tableCol("10%"),
            borderRight: 0,
            borderBottom: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>HSN</Text>
        </View>

        {/* Quantity Column */}
        <View
          style={{
            ...styles.tableCol("10%"),
            borderRight: 0,
            borderBottom: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>Qty</Text>
        </View>

        {/* Tax Percentage Column */}
        <View
          style={{
            ...styles.tableCol("15%"),
            borderRight: 0,
            borderBottom: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>Tax %</Text>
        </View>

        {/* Tax Amount Column */}
        <View
          style={{
            ...styles.tableCol("20%"),
            borderRight: 0,
            borderBottom: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>Tax Amount</Text>
        </View>

        {/* Amount Column */}
        <View
          style={{
            ...styles.tableCol("20%"),
            borderRight: 0,
            borderBottom: 0,
          }}
        >
          <Text style={styles.tableHeadCell}>Amount</Text>
        </View>
      </View>

      {/* Table Body Rows */}
      {data?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <View
            style={{
              ...styles.tableRow,
              borderBottom: 0,
              borderRight: 0,
              borderLeft: 0,
            }}
          >
            {/* Description Cell */}
            <View
              style={{
                ...styles.tableCol("25%"),
                borderBottom: 0,
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              <Text style={styles.tableCell("center")}>{item.name}</Text>
            </View>

            {/* HSN Cell */}
            <View
              style={{
                ...styles.tableCol("10%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text style={styles.tableCell("center")}>{item.hsn}</Text>{" "}
              {/* Assuming item.hsn contains the HSN value */}
            </View>

            {/* Quantity Cell */}
            <View
              style={{
                ...styles.tableCol("10%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text style={styles.tableCell("center")}>1</Text>
            </View>

            {/* SGST Cell */}
            <View
              style={{
                ...styles.tableCol("15%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text style={styles.tableCell("right")}>{`SGST - ${
                item.gstPer || 0
              }%`}</Text>
            </View>

            {/* Tax Amount Cell */}
            <View
              style={{
                ...styles.tableCol("20%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text
                style={{
                  ...styles.tableCell("right"),
                  fontFamily: "customFont",
                }}
              >{`₹ ${item.gst?.toFixed(2)}`}</Text>
            </View>

            {/* Amount Cell */}
            <View
              style={{
                ...styles.tableCol("20%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text
                style={{
                  ...styles.tableCell("right"),
                  fontFamily: "customFont",
                }}
              >{`₹ ${item.amount?.toFixed(2)}`}</Text>
            </View>
          </View>

          {/* CGST Row */}
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol("25%"), border: 0 }}></View>
            <View
              style={{
                ...styles.tableCol("10%"),
                borderTop: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            ></View>
            <View
              style={{
                ...styles.tableCol("10%"),
                borderTop: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            ></View>
            <View
              style={{
                ...styles.tableCol("15%"),
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <Text style={styles.tableCell("right")}>{`CGST - ${
                item.gstPer || 0
              }%`}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol("20%"),
                borderBottom: 0,
                borderRight: 0,
              }}
            >
              <Text
                style={{
                  ...styles.tableCell("right"),
                  fontFamily: "customFont",
                }}
              >{`₹ ${item.gst?.toFixed(2)}`}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol("20%"),
                borderTop: 0,
                borderBottom: 0,
                borderRight: 0,
              }}
            ></View>
          </View>
        </React.Fragment>
      ))}

      {/* Sub Total Row */}
      <View style={styles.tableRow}>
        <View
          style={{ ...styles.tableCol("70%"), borderBottom: 0, borderLeft: 0 }}
        >
          <Text style={styles.tableCell("right")}>Sub Total</Text>
        </View>
        <View
          style={{
            ...styles.tableCol("30%"),
            borderBottom: 0,
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <Text
            style={{ ...styles.tableCell("right"), fontFamily: "customFont" }}
          >
            ₹ {data.subAmount.toFixed(2)}
          </Text>
        </View>
      </View>

      {/* CGST Row */}
      <View style={styles.tableRow}>
        <View
          style={{ ...styles.tableCol("70%"), borderBottom: 0, borderLeft: 0 }}
        >
          <Text style={styles.tableCell("right")}>CGST</Text>
        </View>
        <View
          style={{
            ...styles.tableCol("30%"),
            borderBottom: 0,
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <Text
            style={{ ...styles.tableCell("right"), fontFamily: "customFont" }}
          >
            ₹ {data.gst.toFixed(2)}
          </Text>
        </View>
      </View>

      {/* SGST Row */}
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCol("70%"), borderLeft: 0 }}>
          <Text style={styles.tableCell("right")}>SGST</Text>
        </View>
        <View
          style={{ ...styles.tableCol("30%"), borderLeft: 0, borderRight: 0 }}
        >
          <Text
            style={{ ...styles.tableCell("right"), fontFamily: "customFont" }}
          >
            ₹ {data.gst.toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default DetailTablePDF;
